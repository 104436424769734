import React from "react";
import { useNavigate } from "react-router-dom";
import LatestCirculars from "../Components/LatestCirculars";
import FlipCard from "../Components/UI/FlipCard";

function Landingpage() {
  const navigate = useNavigate();
  const handleReadMore = () => {
    navigate("/allcirculars");
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div>
      <div className="section-one">
        <div className="container">
          <div className="box-row">
            <div className="box-wrap">
              <div className="box-head">
                <span>Latest Circulars</span>
              </div>
              <div style={{ paddingRight: "1rem" }}>
                {/* it is seved from database */}
                <LatestCirculars />
                <div style={{ textAlign: "right" }}>
                  <button className="readMoreButton" onClick={handleReadMore}>
                    view all
                  </button>
                </div>
              </div>
            </div>
            <div className="box-wrap">
              <div className="box-head">
                <span>Chairman's message</span>
              </div>
              <div
                style={{
                  textAlign: "center",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src="/Images/ppdummy.jpeg" alt="chairman" />
              </div>
              <div style={{ padding: "0 1rem 1rem 1rem" }}>
                <p className="tabbed-paragraph">
                  Maheshwaram College of Education and Law Management, located
                  in Bulandshahr, is renowned as one of the premier law colleges
                  in Uttar Pradesh. The institution is dedicated to providing
                  high-quality education ...
                </p>
              </div>
              <div style={{ textAlign: "right" }}>
                <button
                  className="readMoreButton"
                  onClick={() => {
                    navigate("/about-us");
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  Read More ...
                </button>
              </div>
            </div>
            <div className="box-wrap">
              <div className="box-head">
                <span>Admission Procedure</span>
              </div>
              <div
                className="box-body"
                style={{ paddingRight: "1rem", paddingTop: "2rem" }}
              >
                <div style={{ padding: "0 1rem 1rem 1rem" }}>
                  <p className="tabbed-paragraph">
                    Maheshwaram Law College offers both 3-Year LLB and 5-Year
                    BA.LLM programs. The college follows a straightforward and
                    hassle-free admission process. The procedure begins with
                    announcements in national and regional newspapers.
                    Additionally, Maheshwaram Law College employs various
                    communication channels, such as digital campaigns on its
                    website, educational portals, and other electronic mediums,
                    to inform prospective students about the admissions ...
                  </p>
                  <div style={{ textAlign: "right" }}>
                    <button
                      className="readMoreButton"
                      onClick={() => {
                        navigate("/admission-procedure");
                        window.scrollTo({ top: 0, behavior: "smooth" });
                      }}
                    >
                      Read More ...
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-two">
        <div className="container">
          <div className="box-row">
            <FlipCard
              linkTo="/"
              imageSrc="/Images/downloadsfile.jpg"
              imageAlt="Download"
              cardText="Download Circulars"
              minHeight="200px"
            />
            <FlipCard
              linkTo="/admission-procedure"
              imageSrc="/Images/AdmissionOpen.png"
              imageAlt="Admission Open for 2024-25"
              cardText="Admission Open 2024-25"
              minHeight="200px"
            />
            <FlipCard
              linkTo="/registeruser"
              imageSrc="/Images/onlineregistration.jpg"
              imageAlt="Online Registration"
              cardText="Online Registration"
              minHeight="200px"
            />
          </div>
        </div>
      </div>
      <div className="section-two">
        <div className="title-focus">What We Offer</div>
        <div className="container">
          <div className="square-container">
            <div className="square-box">
              <div className="squarebox-text">Carrear counselling session</div>
            </div>
            <div className="square-box">
              <div className="squarebox-text">
                Legal and Correctional Learning Tools
              </div>
            </div>
            <div className="square-box">
              <div className="squarebox-text">Featured Speaker Sessions</div>
            </div>
            <div className="square-box">
              <div className="squarebox-text">Mock Court Proceedings</div>
            </div>
            <div className="square-box">
              <div className="squarebox-text">Legal Training Internship</div>
            </div>
            <div className="square-box">
              <div className="squarebox-text">Assured Placement Success</div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-one">
        <div className="title-focus">Affiliation & Accreditation</div>
        <div className="container">
          <div className="box-row">
            <div className="box-wrap" style={{ textAlign: "center" }}>
              <img
                src="/Images/CCSU-Logo.png"
                alt="CCS University"
                style={{ height: "200px", width: "auto" }}
              ></img>
            </div>
            <div className="box-wrap" style={{ textAlign: "center" }}>
              <img
                src="/Images/BCI-logo.png"
                alt="BCI"
                style={{ height: "200px", width: "auto" }}
              ></img>
            </div>

            <div className="box-wrap" style={{ textAlign: "center" }}>
              <img
                src="/Images/UPgovt-logo.png"
                alt="UP Govt"
                style={{ height: "200px", width: "auto" }}
              ></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Landingpage;
