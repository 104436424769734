import React, { useState, useEffect } from "react";

const MathCaptcha = ({ onVerification, reset }) => {
  const [number1, setNumber1] = useState(Math.floor(Math.random() * 10));
  const [number2, setNumber2] = useState(Math.floor(Math.random() * 10));
  const [userAnswer, setUserAnswer] = useState("");
  const [correctAnswer, setCorrectAnswer] = useState(number1 + number2);

  const generateNewCaptcha = () => {
    const num1 = Math.floor(Math.random() * 10);
    const num2 = Math.floor(Math.random() * 10);
    setNumber1(num1);
    setNumber2(num2);
    setCorrectAnswer(num1 + num2);
    setUserAnswer(""); // Clear previous answer
  };

  useEffect(() => {
    if (reset) {
      generateNewCaptcha(); // Generate a new captcha when reset is true
    }
  }, [reset]);

  useEffect(() => {
    generateNewCaptcha(); // Generate a new captcha when this component mounts
  }, []);

  useEffect(() => {
    // Check the answer whenever the user inputs a value
    if (userAnswer && parseInt(userAnswer) === correctAnswer) {
      onVerification(true);
    } else {
      onVerification(false);
    }
  }, [userAnswer, correctAnswer, onVerification]);

  return (
    <div className="math-captcha">
      <p>
        Solve this: {number1} + {number2} = ?
      </p>
      <input
        id="userAnswer"
        name="userAnswer"
        type="text"
        value={userAnswer}
        onChange={(e) => setUserAnswer(e.target.value)}
        placeholder="Your answer"
      />
    </div>
  );
};

export default MathCaptcha;
