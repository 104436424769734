import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import MathCaptcha from "../Components/UI/MathCaptcha";

const Enquiry = () => {
  const enquiryformref = useRef(null);
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState("");
  const [mobileno, setMobileno] = useState("");
  const [enqmessage, setEnqmessage] = useState("");

  const [showMessage, setShowMessage] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [message, setMessage] = useState("");

  const [isVerified, setIsVerified] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // New state for submitting
  const [resetCaptcha, setResetCaptcha] = useState(false);
  useEffect(() => {
    if (resetCaptcha) {
      setResetCaptcha(false); // Clear the reset state after it has been used
    }
  }, [resetCaptcha]);

  const showMessageWithType = (type, msg) => {
    setMessageType(type);
    setMessage(msg);
    setShowMessage(true);
  };

  useEffect(() => {
    if (showMessage) {
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 10000); // Display message for 10 seconds
      return () => clearTimeout(timer);
    }
  }, [showMessage]);

  const validateFullName = (name) => {
    const fullNameRegex = /^[a-zA-Z\s'-]{3,100}$/;
    return fullNameRegex.test(name);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateMobileNumber = (mobileno) => {
    const mobileRegex = /^[6-9]\d{9}$/;
    return mobileRegex.test(mobileno);
  };

  const resetForm = () => {
    enquiryformref.current.reset();
    setFullname("");
    setEmail("");
    setMobileno("");
    setEnqmessage("");
    setIsSubmitting(false);
    setIsVerified(false);
    setResetCaptcha(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isSubmitting) return; // Prevent multiple submissions
    setIsSubmitting(true); // Set submitting state

    // Client-side validations
    if (!fullname || !email || !mobileno || !enqmessage) {
      showMessageWithType("error", "Please fill all fields.");
      setIsSubmitting(false);
      return;
    }

    if (!validateFullName(fullname)) {
      showMessageWithType(
        "error",
        "Full name can only contain letters, spaces, hyphens, and apostrophes."
      );
      setIsSubmitting(false);
      return;
    }

    if (!validateEmail(email)) {
      showMessageWithType("error", "Please enter a valid email.");
      setIsSubmitting(false);
      return;
    }

    if (!validateMobileNumber(mobileno)) {
      showMessageWithType(
        "error",
        "Please enter a valid 10-digit mobile number starting with 6-9."
      );
      setIsSubmitting(false);
      return;
    }

    if (!isVerified) {
      showMessageWithType("error", "Please complete the captcha");
      setIsSubmitting(false);
      return;
    }
    // Call API to register user
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/enquiry`,
        { fullname, email, mobileno, enqmessage }
      );
      if (response.status === 200 && response.data.status === "success") {
        showMessageWithType(
          response.data.status,
          response.data.message || "Enquiry submitted successfully!"
        );
        resetForm();
      } else {
        showMessageWithType(response.data.status, response.data.message);
      }
    } catch (err) {
      const errorMessage =
        err.response?.data?.message || "Something went wrong, try again later.";
      showMessageWithType("error", errorMessage);
    } finally {
      setResetCaptcha(true); // Reset captcha on error
      setIsSubmitting(false); // Reset submitting state
    }
  };

  return (
    <div className="container">
      <div className="admin-wrap">
        <div className="box-head">
          <span>Enquiry Form</span>
        </div>
        <form
          id="enquiryform"
          name="enquiryform"
          className="login-form"
          ref={enquiryformref}
          onSubmit={handleSubmit}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <img
              style={{ width: "60px", height: "auto" }}
              src="/Images/registration.png"
              alt=""
            />
          </div>
          <div className="form-group">
            <label htmlFor="fullname">Name</label>
            <input
              id="fullname"
              type="text"
              name="fullname"
              autoComplete="name"
              required
              placeholder="Full Name"
              value={fullname}
              onChange={(e) => setFullname(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              id="email"
              type="email"
              name="username"
              autoComplete="email"
              required
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="mobileno">Mobile No</label>
            <input
              id="mobileno"
              type="text"
              name="mobileno"
              autoComplete="tel"
              placeholder="Mobile No"
              required
              value={mobileno}
              onChange={(e) => setMobileno(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="enqmessage">Your Message</label>
            <textarea
              id="enqmessage"
              type="text"
              name="enqmessage"
              autoComplete="off"
              placeholder="Your Message"
              required
              value={enqmessage}
              onChange={(e) => setEnqmessage(e.target.value)}
              style={{ width: "100%", height: "50px" }}
            />
          </div>
          <div className="form-group">
            <MathCaptcha onVerification={setIsVerified} reset={resetCaptcha} />
          </div>
          <div style={{ textAlign: "center" }}>
            <button
              type="submit"
              className="readMoreButton"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Submitting..." : "Submit"}
            </button>
          </div>
          {showMessage && (
            <p className={`${messageType} ${!showMessage && "hidden"}`}>
              {message}
            </p>
          )}
        </form>
      </div>
    </div>
  );
};

export default Enquiry;
