import React, { useState, useEffect,  } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import jsPDF from "jspdf";
import "jspdf-autotable";

const ReportEnquiryCsv = ({ token }) => {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isDownloadingcsv, setIsDownloadingcsv] = useState(false);
  const [isDownloadingpdf, setIsDownloadingpdf] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [message, setMessage] = useState("");
  const showMessageWithType = (type, msg) => {
    setMessageType(type);
    setMessage(msg);
    setShowMessage(true);
  };

  useEffect(() => {
    if (showMessage) {
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 10000); // Display message for 10 seconds
      return () => clearTimeout(timer);
    }
  }, [showMessage]);

  //----------------
  useEffect(() => {
    // Check if the token is present and valid
    const checkToken = () => {
      if (!token) {
        showMessageWithType(
          "error",
          "You need to be logged in to access this page."
        );
        navigate("/adminlogin"); // Redirect to login if no token
        return;
      }

      // check token validity (e.g., decode and check expiration)
      try {
        const { exp } = JSON.parse(atob(token.split(".")[1])); // Decode JWT payload
        if (exp * 1000 < Date.now()) {
          // Token is expired
          showMessageWithType(
            "error",
            "Your session has expired. Please log in again."
          );
          navigate("/adminlogin");
        }
      } catch (err) {
        const errorMessage =
          err.response?.data?.message ||
          "An error occurred while validating your session. Please log in again.";
        showMessageWithType("error", errorMessage);
        navigate("/adminlogin");
      }
    };

    checkToken();
  }, [token, navigate]);

  //----------------
  const validateDates = () => {
    if (!startDate || !endDate) {
      showMessageWithType("error", "Start Date and End Date are required.");
      return false;
    }
    if (new Date(startDate) > new Date(endDate)) {
      showMessageWithType("error", "Start Date cannot be later than End Date.");
      return false;
    }
    return true;
  };

  const handleDownloadCSV = async (e) => {
    e.preventDefault();

    if (!validateDates()) return;
    if (isDownloadingcsv) return; // Prevent multiple submissions
    setIsDownloadingcsv(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/report_csv_enquiries`,
        {
          start_date: startDate,
          end_date: endDate,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            responseType: "blob",
          },
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        `enquiries_report_${new Date().toISOString().slice(0, 10)}.csv`
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Something went wrong, try again later.";
      showMessageWithType("error", errorMessage);
    } finally {
      setIsDownloadingcsv(false);
    }
  };

  const parseCSV = (csvString) => {
    const lines = csvString.trim().split("\n");
    const headers = lines[0]
      .replace(/"/g, "")
      .split(",")
      .map((header) => header.trim());

    return lines.slice(1).map((line) => {
      const data = line.split(",").map((cell) => cell.replace(/"/g, "").trim());
      return headers.reduce((obj, header, index) => {
        obj[header] = data[index];
        return obj;
      }, {});
    });
  };

  const handleDownloadPDF = async (e) => {
    e.preventDefault();
    if (!validateDates()) return;
    if (isDownloadingpdf) return;
    setIsDownloadingpdf(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/report_csv_enquiries`,
        {
          start_date: startDate,
          end_date: endDate,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            responseType: "text",
          },
        }
      );

      // Parse the CSV string
      const data = parseCSV(response.data);

      // Create a new PDF document
      const doc = new jsPDF("landscape");
      // const doc = new jsPDF();
      const tableColumn = [
        "Enquiry DATE",
        "Full Name",
        "E-Mail",
        "Mobile No",
        "Enquiry",
      ];
      const tableRows = data.map((row) => [
        row["Enquiry DATE"],
        row["Full Name"],
        row["E-Mail"],
        row["Mobile No"],
        row["Enquiry"],
      ]);

      doc.setFontSize(18);
      doc.text("Enquiries Report", 150, 22, { align: "center" });

      // Add a line below the title
      doc.setDrawColor(200); // Set line color
      doc.setLineWidth(0.5);

      // Generate the table with adjusted column widths
      doc.autoTable({
        head: [tableColumn],
        body: tableRows,
        startY: 30,
        autoWidth: true,
        // columnStyles: {
        //   0: { cellWidth: 25 }, // Enquiry DATE
        //   1: { cellWidth: 60 }, // Full Name
        //   2: { cellWidth: 40 }, // E-Mail
        //   3: { cellWidth: 28 }, // Mobile No
        //   4: { cellWidth: 60 }, // Enquiry
        // },
        styles: {
          overflow: "linebreak", // Allows text to wrap if it's too long
          cellPadding: 2,
          fontSize: 10,
        },
        margin: { top: 30, right: 10, bottom: 10, left: 10 }, // Adjust margins if needed
      });

      // Save the PDF
      doc.save(`enquiries_report_${new Date().toISOString().slice(0, 10)}.pdf`);
    } catch (err) {
      const errorMessage =
        err.response?.data?.message ||
        "Error downloading pdf, try again later.";
      showMessageWithType("error", errorMessage);
    } finally {
      setIsDownloadingpdf(false); // Reset submitting state
    }
  };

  return (
    <div className="container">
      <div className="admin-wrap">
        <div className="box-head">
          <span>Report : Enquiries</span>
        </div>
        <form
          name="reportenquiry"
          className="login-form"
          
        >
          <div className="form-group">
            <label htmlFor="startDate">Start Date:</label>
            <input
              type="date"
              id="startDate"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
              required
              style={{ fontSize: "1rem" }}
            />
          </div>
          <div className="form-group">
            <label htmlFor="endDate">End Date:</label>
            <input
              type="date"
              id="endDate"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
              required
              style={{ fontSize: "1rem" }}
            />
          </div>
          <div className="divReportButtons">
            <button
              type="submit"
              className="readMoreButton"
              disabled={isDownloadingcsv}
              onClick={handleDownloadCSV}
            >
              {isDownloadingcsv ? "Downloading..." : "Download CSV"}
            </button>
            <button
              className="readMoreButton"
              disabled={isDownloadingpdf}
              onClick={handleDownloadPDF}
            >
              {isDownloadingpdf ? "Downloading..." : "Download PDF"}
            </button>
          </div>
          {showMessage && (
            <p className={`${messageType} ${!showMessage && "hidden"}`}>
              {message}
            </p>
          )}
        </form>
      </div>
    </div>
  );
};

export default ReportEnquiryCsv;
