import { Link } from "react-router-dom";

function FlipCard({
  linkTo,
  imageSrc,
  imageAlt,
  cardText,
  minHeight = "200px",
}) {
  return (
    <div className="box-wrap" style={{ minHeight: minHeight }}>
      <Link
        to={linkTo}
        onClick={() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }}
      >
        <div className="flip-card">
          <div className="flip-card-inner">
            <div className="flip-card-front">
              <figure style={{ height: minHeight }}>
                <img
                  src={imageSrc}
                  alt={imageAlt}
                  style={{
                    height: "100%",
                    width: "auto",
                    maxWidth: "100%",
                    objectFit: "cover",
                  }}
                />
              </figure>
            </div>
            <div className="flip-card-back">
              <div className="squarebox-text">{cardText}</div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default FlipCard;
