import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Admincirculars from "./Admincirculars";

const UploadCircular = ({ token, onUploadSuccess }) => {
  const [file, setFile] = useState(null);
  const formUpload = useRef(null);
  const navigate = useNavigate();
  const [showMessage, setShowMessage] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [message, setMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [refreshKey, setRefreshKey] = useState(0);
  const handleRefresh = () => {
    // Update the refreshKey to force re-rendering of the file list component AllCirculars.jsx
    setRefreshKey((prevKey) => prevKey + 1);
  };

  const showMessageWithType = (type, msg) => {
    setMessageType(type);
    setMessage(msg);
    setShowMessage(true);
  };

  useEffect(() => {
    if (showMessage) {
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 3000); // Display message for 3 seconds
      return () => clearTimeout(timer); // Clear timeout if component unmounts
    }
  }, [showMessage]);

  //----------------
  useEffect(() => {
    // Check if the token is present and valid
    const checkToken = () => {
      if (!token) {
        showMessageWithType(
          "error",
          "You need to be logged in to access this page."
        );
        navigate("/adminlogin");
        return;
      }

      // check token validity (e.g., decode and check expiration)
      try {
        const { exp } = JSON.parse(atob(token.split(".")[1])); // Decode JWT payload
        if (exp * 1000 < Date.now()) {
          // Token is expired
          showMessageWithType(
            "error",
            "Your session has expired. Please log in again."
          );
          navigate("/adminlogin");
        }
      } catch (err) {
        const errorMessage =
          err.response?.data?.message ||
          "An error occurred while validating your session. Please log in again.";
        showMessageWithType("error", errorMessage);
        navigate("/adminlogin");
      }
    };

    checkToken();
  }, [token, navigate]);

  //----------------

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      const maxFileSize =
        parseInt(process.env.REACT_APP_ALLOWED_PDF_SIZE, 10) || 2 * 1024 * 1024; // Default to 1 MB if not set
      // Fetch the allowed file types from the .env file and split into an array
      const allowedTypes = process.env.REACT_APP_ALLOWED_PDF_TYPE
        ? process.env.REACT_APP_ALLOWED_PDF_TYPE.split(",").map((type) =>
            type.trim()
          )
        : [];
      if (!allowedTypes.includes(selectedFile.type)) {
        showMessageWithType(
          "error",
          "Invalid file type. Please select a pdf file (pdf)."
        );
        setFile(null);
        formUpload.current.reset();
        return;
      }
      // Validate file size
      if (selectedFile.size > maxFileSize) {
        showMessageWithType(
          "error",
          `File size exceeds the limit of --2Mb-- ${process.env.REACT_APP_ALLOWED_PDF_SIZE}. Please select a smaller file.`
        );
        setFile(null);
        formUpload.current.reset();
        return;
      }
      setFile(selectedFile);
      setMessage("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return; // Prevent multiple submissions
    setIsSubmitting(true); // Set submitting state

    if (localStorage.getItem("token") === null) {
      setIsSubmitting(false);
      navigate("/");
      alert("please login again");
      return;
    }
    if (!file) {
      showMessageWithType("error", "No file selected");
      setIsSubmitting(false);
      return;
    }

    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/uploadCircular`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`, //ensure backticks ` are used with bearer $token
            "Content-Type": "multipart/form-data",
          },
        }
      );
      formUpload.current.reset();
      setIsSubmitting(false);
      setFile(null);
      showMessageWithType(response.data.status, response.data.message);

      // Call the onUploadSuccess prop to refresh the pdf list
      if (response.status === 200) {
        if (onUploadSuccess) {
          onUploadSuccess(response.data);
        }
        handleRefresh();
        showMessageWithType(response.data.status, response.data.message);
      }
    } catch (err) {
      const errorMessage =
        err.response?.data?.message || "Something went wrong, try again later.";
      showMessageWithType("error", errorMessage);
    }
  };

  return (
    <div className="container">
      <div className="admin-wrap">
        <div className="box-head">
          <span>Upload Circulars</span>
        </div>

        <form
          style={{ width: "100%" }}
          onSubmit={handleSubmit}
          ref={formUpload}
          encType="multipart/form-data"
        >
          <div style={{ justifyContent: "center", textAlign: "center" }}>
            {showMessage && (
              <p className={`${messageType} ${!showMessage && "hidden"}`}>
                {message}
              </p>
            )}
            <div className="uploadfile-control">
              <input
                type="file"
                onChange={handleFileChange}
                accept={process.env.REACT_APP_ALLOWED_PDF_TYPES}
              />
              <button type="submit" disabled={isSubmitting}>
                {isSubmitting ? "Uploading..." : "Upload"}
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="admin-wrap">
        <Admincirculars key={refreshKey} token={token} />{" "}
      </div>
    </div>
  );
};
export default UploadCircular;
