import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// import HeadComponent from "../../Components/UI/HeadComponent";
import axios from "axios";

const ChangePassword = ({ token }) => {
  const [showMessage, setShowMessage] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [message, setMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const [username, setUsername] = useState("");
  const [currentpassword, setCurrentpassword] = useState("");
  const [newpassword, setNewpassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");

  const navigate = useNavigate();

  const showMessageWithType = (type, msg) => {
    setMessageType(type);
    setMessage(msg);
    setShowMessage(true);
    setIsSuccess(type === "success");
  };

  useEffect(() => {
    if (showMessage) {
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 5000); // Display message for 5 seconds
      return () => clearTimeout(timer);
    }
  }, [showMessage]);
  //----------------
  useEffect(() => {
    // Check if the token is present and valid
    const checkToken = () => {
      if (!token) {
        showMessageWithType(
          "error",
          "You need to be logged in to access this page."
        );
        showMessageWithType("error", "upload photo token error", token);
        navigate("/adminlogin"); // Redirect to login if no token
        return;
      }

      // Optionally check token validity (e.g., decode and check expiration)
      try {
        const { exp } = JSON.parse(atob(token.split(".")[1])); // Decode JWT payload
        if (exp * 1000 < Date.now()) {
          // Token is expired
          console.log("upload photo token expired", exp * 1000);
          showMessageWithType(
            "error",
            "Your session has expired. Please log in again."
          );
          navigate("/adminlogin");
        }
      } catch (error) {
        console.error("Error decoding token:", error);
        showMessageWithType(
          "error",
          "An error occurred while validating your session. Please log in again."
        );
        navigate("/adminlogin");
      }
    };

    checkToken();
  }, [token, navigate]);

  //----------------
  // Redirect after successful password reset request
  useEffect(() => {
    if (isSuccess) {
      const redirectTimer = setTimeout(() => {
        navigate("/");
      }, 15000);
      return () => clearTimeout(redirectTimer);
    }
  }, [isSuccess, navigate]);

  // Password validation function
  const validatePassword = (password) => {
    const passwordRegex =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    return passwordRegex.test(password);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (localStorage.getItem("token") === null) {
      alert("please login again");
      navigate("/");
      return;
    }
    if (newpassword !== confirmpassword) {
      showMessageWithType(
        "error",
        "New Password & Confirm Password do not match."
      );
      return;
    }

    if (!validatePassword(newpassword)) {
      showMessageWithType(
        "error",
        "Password must be at least 8 characters long, contain at least one uppercase letter, one number, and one special character."
      );
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/changepassword`,
        { username, currentpassword, newpassword },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Pass JWT token in Authorization header
          },
        }
      );
      localStorage.removeItem("token");
      showMessageWithType(response.data.status, response.data.message);
    } catch (error) {
      showMessageWithType("error", "An error occurred. Please try again.");
    }
  };

  return (
    <>
      {/* <HeadComponent /> */}
      <div className="container">
        <div className="admin-wrap" style={{ marginTop: "10px !important" }}>
          <div className="box-head">
            <span>Change Password</span>
          </div>
          <div className="upload-container">
            <form className="login-form" onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="username">User Name</label>
                <input
                  id="username"
                  type="text"
                  name="username"
                  autoComplete="name"
                  required
                  placeholder="Username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="currentpassword">Current Password</label>
                <input
                  id="currentpassword"
                  type="password"
                  name="password"
                  autoComplete="current-password"
                  placeholder="Current Password"
                  required
                  value={currentpassword}
                  onChange={(e) => setCurrentpassword(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="newpassword">New Password</label>
                <input
                  id="newpassword"
                  type="password"
                  name="newpassword"
                  autoComplete="off"
                  placeholder="New Password"
                  required
                  value={newpassword}
                  onChange={(e) => setNewpassword(e.target.value)}
                />
              </div>
              <div className="form-group">
                <label htmlFor="confirmpassword">Confirm Password</label>
                <input
                  id="confirmpassword"
                  type="password"
                  name="confirmpassword"
                  autoComplete="off"
                  placeholder="Confirm Password"
                  required
                  value={confirmpassword}
                  onChange={(e) => setConfirmpassword(e.target.value)}
                />
              </div>

              <div style={{ textAlign: "center" }}>
                <button className="readMoreButton" type="submit">
                  Submit
                </button>
              </div>
              <div>
                {showMessage && (
                  <p className={`${messageType} ${!showMessage && "hidden"}`}>
                    {message}
                  </p>
                )}
              </div>
            </form>

            {isSuccess && (
              <div
                style={{
                  textAlign: "center",
                  marginTop: "20px",
                  padding: "20px",
                }}
              >
                <p>
                  Your password has been changed successfully! You may now login
                  with your new password. You will be redirected to the home
                  page in 15 seconds.
                </p>
                <p>
                  If not redirected, click <a href="/">here</a> to go to the
                  home page.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
