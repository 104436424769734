import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom"; 
import HeadComponent from "../../Components/UI/HeadComponent";
import axios from "axios";

const ResetPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [message, setMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false); 

  const navigate = useNavigate(); 

  // Extract token from the query string
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const pwdresettoken = params.get("pwdresettoken");

  const showMessageWithType = (type, msg) => {
    setMessageType(type);
    setMessage(msg);
    setShowMessage(true);
    setIsSuccess(type === "success"); 
  };

  useEffect(() => {
    if (showMessage) {
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 5000); // Display message for 5 seconds
      return () => clearTimeout(timer);
    }
  }, [showMessage]);

  // Redirect after successful password reset
  useEffect(() => {
    if (isSuccess) {
      const redirectTimer = setTimeout(() => {
        navigate("/"); 
      }, 15000);
      return () => clearTimeout(redirectTimer); 
    }
  }, [isSuccess, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      showMessageWithType("error", "Passwords do not match.");
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/resetpassword`,
        { pwdresettoken, newPassword }
      );
      showMessageWithType(response.data.status, response.data.message);
    } catch (error) {
      showMessageWithType("error", "An error occurred. Please try again.");
    }
  };

  return (
    <>
      <HeadComponent />
      <div className="container">
        <div className="admin-wrap">
          <div className="box-head">
            <span>Reset Password</span>
          </div>
          <div className="upload-container">
            <form className="login-form" onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="newpassword">New Password</label>
                <input
                  id="newpassword"
                  type="password"
                  value={newPassword}
                  autoComplete="new-password"
                  placeholder="New Password"
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                />
              </div>

              <div className="form-group">
                <label htmlFor="confirmpassword">Confirm Password</label>
                <input
                  id="confirmpassword"
                  name="confirmpassword"
                  type="password"
                  autoComplete="off"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </div>
              <div style={{ textAlign: "center" }}>
                <button className="readMoreButton" type="submit">
                  Submit
                </button>
              </div>
              <div>
                {showMessage && (
                  <p className={`${messageType} ${!showMessage && "hidden"}`}>
                    {message}
                  </p>
                )}
              </div>
            </form>

            {isSuccess && (
              <div style={{ textAlign: "center", marginTop: "20px" }}>
                <p>
                  Password reset successful! You will be redirected to the home
                  page in 15 seconds.
                </p>
                <p>
                  If not redirected, click <a href="/">here</a> to go to the
                  home page.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
