import React from "react";

export default function CourseAndProgramme() {
  return (
    <div style={{ marginTop: "0rem", paddingTop: "0rem" }}>
      <div className="page-head">
        <span>Course Offered</span>
      </div>

      <p className="no-margin lead">B.A. LL.B. (5 Years)</p>
      <p className="no-margin">Eligibility:</p>
      <p className="tabbed-paragraph">
        Candidates seeking admission to the B.A. LL.B. program (5 years) must
        have completed a degree (10+2) from a recognized university with a
        minimum of 45% marks in their qualifying examination. A 5% relaxation in
        marks is granted to candidates from SC/ST categories.
      </p>
      <p className="no-margin small">Intake Capacity:</p>
      <p className="no-margin small">
        The B.A. LL.B. (5 Years) program has 120 seats available.
      </p>
      <hr></hr>

      <p className="no-margin lead">LL.M. (2 Years)</p>
      <p className="no-margin">Eligibility:</p>
      <p className="tabbed-paragraph">
        Candidates seeking admission to the LL.M. program (2 years) must have
        completed a degree (10+2+3+LLB) from a recognized university with a
        minimum of 45% marks in their qualifying examination. A 5% relaxation in
        marks is granted to candidates from SC/ST categories.
      </p>
      <p className="no-margin small">Intake Capacity:</p>
      <p className="no-margin small">
        The LL.M. (2 Years) program has 60 seats available.
      </p>
      <hr></hr>
      <p className="no-margin lead">LL.B. (3 Years)</p>
      <p className="no-margin">Eligibility:</p>
      <p className="tabbed-paragraph">
        Candidates seeking admission to the LL.B. program (3 years) must have
        completed a degree (10+2+3) from a recognized university with a minimum
        of 45% marks in their qualifying examination. A 5% relaxation in marks
        is granted to candidates from SC/ST categories.
      </p>
      <p className="no-margin small">Intake Capacity:</p>
      <p className="no-marging small">
        The LL.B. (3 Years) program has 180 seats available.
      </p>
    </div>
  );
}
