import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Admincirculars = ({ token }) => {
  const [pdfList, setpdfList] = useState([]);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const navigate = useNavigate();

  // common functions
  const showMessageWithType = (type, msg) => {
    setMessageType(type);
    setMessage(msg);
    setShowMessage(true);
  };

  useEffect(() => {
    if (showMessage) {
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showMessage]);

  //----------------
  useEffect(() => {
    // Check if the token is present and valid
    const checkToken = () => {
      if (!token) {
        showMessageWithType(
          "error",
          "You need to be logged in to access this page."
        );
        navigate("/adminlogin"); // Redirect to login if no token
        return;
      }

      // checking token validity (e.g., decode and check expiration)
      try {
        const { exp } = JSON.parse(atob(token.split(".")[1])); // Decode JWT payload
        if (exp * 1000 < Date.now()) {
          // Token is expired
          showMessageWithType(
            "error",
            "Your session has expired. Please log in again."
          );
          navigate("/adminlogin");
        }
      } catch (error) {
        console.error("Error decoding token:", error);
        showMessageWithType(
          "error",
          "An error occurred while validating your session. Please log in again."
        );
        navigate("/adminlogin");
      }
    };

    checkToken();
  }, [token, navigate]);
  //common function ends

  // Call API to Fetch uploaded Files from the server
  useEffect(() => {
    const fetchedPdfFiles = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/getCirculars`
        );
        setpdfList(response.data.pdfArray); // Adjust based on your API response
      } catch (err) {
        const errorMessage =
          err.response?.data?.message ||
          "Failed to load file. Please try again.";
        showMessageWithType("error", errorMessage);
      }
    };
    fetchedPdfFiles();
  }, [token]); // Re-fetch files whenever the token changes

  const handleDelete = async (fileName) => {
    if (!token) {
      navigate("/adminlogin");
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/deleteCircular`, // Don't pass fileName in URL
        { fileName }, // Sending fileName in request body
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // Remove the deleted file from the list
      setpdfList((prevPdfList) =>
        prevPdfList.filter((pdf) => pdf.fileName !== fileName)
      );
      showMessageWithType(response.data.status, response.data.message);
    } catch (err) {
      const errorMessage =
        err.response?.data?.message ||
        "Something went wrong. Please try again.";
      showMessageWithType("error", errorMessage);
    }
  };

  return (
    <div className="container">
      <div className="gallery-container">
        <h6 className="divider-full-width">Uploaded Circulars</h6>
        <div style={{ height: "30px" }}>
          {showMessage && (
            <p className={`${messageType} ${!showMessage && "hidden"}`}>
              {message}
            </p>
          )}
        </div>
        <table className="pdf-table">
          <thead>
            <tr>
              <th>Circular Name</th>
              {/* <th>Circular Unique Name</th> */}
              <th>Download</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {pdfList.map((pdf) => (
              <tr key={pdf.id}>
                <td>{pdf.realname}</td>
                <td style={{ textAlign: "center" }}>
                  <a
                    href={pdf.url}
                    download={pdf.fileName}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Download
                  </a>
                </td>
                <td style={{ textAlign: "center" }}>
                  <button
                    onClick={() => {
                      if (window.confirm("Are you sure to delete ?")) {
                        handleDelete(pdf.fileName);
                      }
                    }}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="divider-full-width"></div>
      </div>
    </div>
  );
};

export default Admincirculars;
