import React from "react";
import { Link } from "react-router-dom";
import SocialNavbar from "./SocialNavbar";
import Brand from "./Brand";

const HeadComponent = () => {
  return (
    <>
      <div className="social-navbar-bg-color">
        {/* enquiry button */}
        <div className="socialNavbarContainer">
          <div className="collegecode">
            <span>College Code : 320</span>
          </div>
          <div className="header-social-navbar">
            <SocialNavbar
              facebookURL={"https://www.facebook.com/"}
              twitterUrl={"https://x.com/"}
              instagramUrl={"https://www.instagram.com"}
              youtubeUrl={"https://youtube.com/"}
              telegramUrl={"https://telegram.org/"}
              whatsappUrl={"https://web.whatsapp.com/"}
            />
          </div>
          {/* register button */}
          <div className="rightalignedButton">
            <Link to="/registeruser">
              <button className="readMoreButton colorChange">Register</button>
            </Link>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="header">
          <Brand />
        </div>
      </div>
      <div className="divider-full-width"></div>
    </>
  );
};

export default HeadComponent;
