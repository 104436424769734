import React from "react";
import CollegeLocation from "../../Components/UI/CollegeLocation";
function Contactus() {
  const iconStyle = {
    marginRight: "0.5rem",
    color: "#333",
    textDecoration: "none",
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="page-head">
        <span>Contact Us</span>
      </div>
      <h6 style={{ textAlign: "left", font: "600" }}>Address:</h6>
      <p style={{ margin: ".5rem .5rem 1rem 5rem" }}>
        <span>
          Maheshwaram College of Education And Law Management,
          <br />
          Bhensora, Near Siyana, Bulandshahr, <br />
          Uttar Pradesh Pin : 245412
        </span>
      </p>
      <p style={{ marginLeft: "5rem" }}>
        <span>
          {"\uD83D\uDCF1"} 9997908588 , 8938855524
          <br />
          {"\uD83D\uDCF1"} 9760082425 , 9627250700
          <br />
          <a
            style={iconStyle}
            href="mailto:principalmclb.gmail.com"
            target="_blank"
            rel="noreferrer"
          >
            ✉️ principalmclb@gmail.com
          </a>
        </span>
      </p>
      <h6 style={{ textAlign: "left", font: "600" }}>College location: </h6>
      <div className="bordered-div">
        <CollegeLocation />
      </div>
    </div>
  );
}

export default Contactus;
