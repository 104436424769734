import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const AdminGallery = ({ token }) => {
  const [images, setImages] = useState([]);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const navigate = useNavigate();

  // common functions
  const showMessageWithType = (type, msg) => {
    setMessageType(type);
    setMessage(msg);
    setShowMessage(true);
  };

  useEffect(() => {
    if (showMessage) {
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showMessage]);

  //----------------
  useEffect(() => {
    // Check if the token is present and valid
    const checkToken = () => {
      if (!token) {
        showMessageWithType(
          "error",
          "You need to be logged in to access this page."
        );
        navigate("/adminlogin");
        return;
      }

      //checking token validity (e.g., decode and check expiration)
      try {
        const { exp } = JSON.parse(atob(token.split(".")[1])); // Decode JWT payload
        if (exp * 1000 < Date.now()) {
          // Token is expired
          showMessageWithType(
            "error",
            "Your session has expired. Please log in again."
          );
          navigate("/adminlogin");
          return;
        }
      } catch (error) {
        console.error("Error decoding token:", error);
        showMessageWithType(
          "error",
          "An error occurred while validating your session. Please log in again."
        );
        navigate("/adminlogin");
      }
    };

    checkToken();
  }, [token, navigate]);
  //common function ends

  useEffect(() => {
    // Fetch uploaded images from the server
    const fetchImages = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/getPhotos`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setImages(response.data.images);
      } catch (err) {
        console.error("Error fetching images:", err);
        showMessageWithType(
          "error",
          "Failed to load images. Please try again."
        );
      }
    };

    fetchImages();
  }, [token]); // Re-fetch images whenever the token changes

  const handleDelete = async (imageName) => {
    if (!token) {
      navigate("/adminlogin");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/deletePhoto`, // Don't pass imageName in URL
        { imageName }, // Sending imageName in request body
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      // Remove the deleted image from the gallery
      setImages((prevImages) =>
        prevImages.filter((img) => img.imagename !== imageName)
      );
      if ((response.message = "undefined")) {
        response.message = "";
      }
      showMessageWithType("success", "Deleted successfully" + response.message);
    } catch (err) {
      showMessageWithType(
        "error",
        err.response?.data?.message || "Something went wrong. Please try again."
      );
      console.error(
        "Error details:",
        err.response ? err.response.data : err.message
      );
    }
  };

  return (
    <div className="container">
      <div className="gallery-container">
        <h6 className="divider-full-width">Uploaded Images</h6>
        {showMessage && (
          <p className={`${messageType} ${!showMessage && "hidden"}`}>
            {message}
          </p>
        )}
        <div className="gallery-grid">
          {images.map((image) => (
            <div key={image.imagename} className="gallery-item">
              <img
                src={image.imageurl}
                alt={image.imagename}
                className="gallery-image"
              />{" "}
              <button
                onClick={() => {
                  if (window.confirm("Are you sure to delete ?")) {
                    handleDelete(image.imagename);
                  }
                }}
                className="delete-button"
              >
                Delete
              </button>
            </div>
          ))}
        </div>
        <div className="divider-full-width"></div>
      </div>
    </div>
  );
};

export default AdminGallery;
