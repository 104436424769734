import React, { useState } from "react";
import { Link } from "react-router-dom";

// Tags (menu items) with submenus
const Tags = [
  { name: "Home", link: "/" },
  {
    name: "About Us",
    submenu: [
      { name: "About Us", link: "/about-us" },
      { name: "Chairman's Message", link: "/chairmans-message" },
      { name: "Vision and Mission", link: "/vision-and-mission" },
      { name: "Aim and Objective", link: "/aim-and-objective" },
      { name: "Dress Code", link: "/dress-code" },
      { name: "Discipline", link: "/discipline" },
      { name: "General Rules", link: "/general-rules" },
    ],
  },
  // { name: "Recognition", link: "/recognition" },
  {
    name: "Courses",
    link: "#",
    submenu: [
      { name: "Courses & Programmes", link: "/courses-and-programmes" },
    ],
  },
  {
    name: "Academics",
    link: "#",
    submenu: [{ name: "Admission Procedure", link: "/admission-procedure" }],
  },
  // { name: "Curriculum", link: "/curriculum" },
  { name: "Gallery", link: "/imagegallery" },
  { name: "Contact Us", link: "/contactus" },
  {
    name: "Login",
    submenu: [
      { name: "Admin login", link: "/adminlogin" },
      { name: "Forgot Password", link: "/forgotpassword" },
    ],
  },
  { name: "Enquiry", link: "/enquiry" },
];

const Sidebar = ({ toggleSidebar, setToggleSidebar }) => {
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  // const handleClick = () => {
  //   // Scroll to the top of the page
  //   window.scrollTo({ top: 0, behavior: "smooth" });
  // };
  // Toggle submenu visibility
  const handleSubMenuToggle = (index) => {
    setActiveSubMenu(activeSubMenu === index ? null : index);
  };

  // Handle menu item click
  const handleMenuItemClick = (index) => {
    setActiveSubMenu(activeSubMenu === index ? null : index);
    setToggleSidebar(false); // Close the sidebar
  };

  return (
    toggleSidebar && (
      <div className="sidebar-container">
        <ul className="sidebar-ul">
          {Tags.map((item, index) => (
            <li key={index} style={{ marginBottom: "0.5rem" }}>
              {" "}
              {/* Reduced vertical space */}
              <Link
                to={item.link}
                className="sidebar-link"
                onClick={() => {
                  if (item.submenu) {
                    handleSubMenuToggle(index);
                  } else {
                    handleMenuItemClick(index);
                  }
                }}
              >
                {item.name}
              </Link>
              {/* Render submenu if available */}
              {item.submenu && activeSubMenu === index && (
                <ul className="sidebar-submenu-ul">
                  {item.submenu.map((subItem, subIndex) => (
                    <li key={subIndex} style={{ marginBottom: "0.25rem" }}>
                      <Link
                        to={subItem.link}
                        className="sidebar-submenu-link"
                        onClick={() => handleMenuItemClick(index)}
                      >
                        {subItem.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
    )
  );
};

export default Sidebar;
