import { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import Layout from "./Layout";
import LayoutFull from "./LayoutFull";
import LayoutAdmin from "./LayoutAdmin";
import PrivateRoute from "./Components/PrivateRoute";
// import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import Chairmansmessage from "./pages/AboutUs/Chairmansmessage";
import Visionandmission from "./pages/AboutUs/Visionandmission";
import Aimandobjective from "./pages/AboutUs/AimandObjective";
import Dresscode from "./pages/AboutUs/Dresscode";
import Discipline from "./pages/AboutUs/Discipline";
import Generalrules from "./pages/AboutUs/Generalrules";
import AdmissionProcedure from "./pages/Admission/AdmissionProcedure";
import CourseAndProgramme from "./pages/Courses/CourseAndProgramme";
import Contactus from "./pages/AboutUs/Contactus";
import ImageGallery from "./pages/ImageGallery";
import StudentLogin from "./pages/Admin/StudentLogin";
import Landingpage from "./pages/Landingpage";
import Adminlogin from "./pages/Admin/Adminlogin";
import UploadPhoto from "./pages/Admin/UploadPhoto";
import Registeruser from "./pages/Admin/Registeruser";
import UploadCircular from "./pages/Admin/UploadCircular";
import Admincirculars from "./pages/Admin/Admincirculars";
import AdminGallery from "./pages/Admin/AdminGallery";
import AllCirculars from "./pages/AllCirculars";
import ForgotPassword from "./pages/Admin/ForgotPassword";
import ResetPassword from "./pages/Admin/ResetPassword";
import ChangePassword from "./pages/Admin/ChangePassword";
import ReportEnquiry from "./pages/Admin/ReportEnquiry";
import Enquiry from "./pages/Enquiry";
import Logout from "./Components/Logout";
const App = () => {
  const [token, setToken] = useState(null);

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    if (storedToken) {
      setToken(storedToken);
    } else {
      setToken(null);
      localStorage.removeItem("token");
    }
  }, [token]);

  return (
    <BrowserRouter>
      <Routes>
        {/* routes  without any layout */}
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route path="/resetpassword" element={<ResetPassword />} />

        {/* Full-Width Layout without Sidebars for Home Page */}
        <Route path="/" element={<LayoutFull />}>
          <Route index element={<Landingpage />} />
          <Route
            path="adminlogin"
            element={<Adminlogin setToken={setToken} />}
          />
          <Route path="enquiry" element={<Enquiry />} />
          <Route path="registeruser" element={<Registeruser />} />
          <Route path="logout" element={<Logout />} />
        </Route>

        {/* Layout with circulars Sidebars for other pages */}
        <Route path="/" element={<Layout />}>
          <Route path="about-us" element={<AboutUs />} />
          <Route path="*" element={<Navigate to="/" replace />} />
          <Route path="chairmans-message" element={<Chairmansmessage />} />
          <Route path="vision-and-mission" element={<Visionandmission />} />
          <Route path="aim-and-objective" element={<Aimandobjective />} />
          <Route path="dress-code" element={<Dresscode />} />
          <Route path="discipline" element={<Discipline />} />
          <Route path="general-rules" element={<Generalrules />} />
          <Route
            path="courses-and-programmes"
            element={<CourseAndProgramme />}
          />
          <Route path="admission-procedure" element={<AdmissionProcedure />} />
          <Route path="allcirculars" element={<AllCirculars />} />
          <Route path="contactus" element={<Contactus />} />
          <Route path="imagegallery" element={<ImageGallery />} />
          <Route path="studentlogin" element={<StudentLogin />} />
        </Route>

        {/* Admin Layout for admin pages */}
        <Route path="/" element={<LayoutAdmin />}>
          <Route
            path="uploadcircular"
            element={
              <PrivateRoute token={token}>
                <UploadCircular token={token} />
              </PrivateRoute>
            }
          />
          <Route
            path="uploadphoto"
            element={
              <PrivateRoute token={token}>
                <UploadPhoto token={token} />
              </PrivateRoute>
            }
          />
          <Route
            path="admincirculars"
            element={
              <PrivateRoute token={token}>
                <Admincirculars token={token} />
              </PrivateRoute>
            }
          />

          <Route
            path="admingallery"
            element={
              <PrivateRoute token={token}>
                <AdminGallery token={token} />
              </PrivateRoute>
            }
          />
          <Route
            path="changepassword"
            element={
              <PrivateRoute token={token}>
                <ChangePassword token={token} />
              </PrivateRoute>
            }
          />
          <Route
            path="reportenquiry"
            element={
              <PrivateRoute token={token}>
                <ReportEnquiry token={token} />
              </PrivateRoute>
            }
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
