import React, { useEffect, useState, useRef } from "react";
import axios from "axios";

const LatestCircularHscroll = () => {
  const [pdfArray, setPdfArray] = useState([]);
  const listRef = useRef(null);
  const scrollIntervalRef = useRef(null);

  // Start horizontal auto-scroll
  useEffect(() => {
    const listElement = listRef.current;

    const startAutoScroll = () => {
      if (!listElement) return; // Ensure the element is available before starting

      scrollIntervalRef.current = setInterval(() => {
        listElement.scrollLeft += 1; // Scroll horizontally

        // Reset to the left (start) if the end is reached
        if (
          listElement.scrollLeft + listElement.clientWidth >=
          listElement.scrollWidth
        ) {
          listElement.scrollLeft = 0;
        }
      }, 50); // Adjust speed here (50ms delay between scrolls)
    };

    if (pdfArray.length > 0) {
      startAutoScroll();
    }

    // Clear the interval on component unmount
    return () => clearInterval(scrollIntervalRef.current);
  }, [pdfArray]);

  // Pause scrolling on mouse hover
  const handleMouseEnter = () => {
    clearInterval(scrollIntervalRef.current);
  };

  // Resume scrolling on mouse leave
  const handleMouseLeave = () => {
    const listElement = listRef.current;
    scrollIntervalRef.current = setInterval(() => {
      if (listElement) {
        listElement.scrollLeft += 1;

        if (
          listElement.scrollLeft + listElement.clientWidth >=
          listElement.scrollWidth
        ) {
          listElement.scrollLeft = 0;
        }
      }
    }, 50); // Scroll speed: moves 1 pixel every 50ms
  };

  // Fetch the list of circulars from the API
  useEffect(() => {
    const fetchedPdfFiles = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/getCirculars`
        );
        setPdfArray(response.data.pdfArray);
      } catch (error) {
        console.error("Error fetching PDF files:", error);
      }
    };

    fetchedPdfFiles();
  }, []);

  // if (!pdfArray || pdfArray.length === 0) {
  //   return <p>Maheshwaram College of Education and Law Mangement</p>;
  // }

  return (
    <div
      className="scroll-container"
      ref={listRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <ul className="pdfList" style={{ display: "inline-flex", padding: 0, margin: 0 }}>
        {pdfArray.map((pdf) => (
          <li key={pdf.id} className="scroll-item">
            <a href={pdf.url} target="_blank" rel="noopener noreferrer">
              {pdf.realname}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LatestCircularHscroll;
