import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import MathCaptcha from "../../Components/UI/MathCaptcha";

const AdminLogin = ({ setToken }) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showMessage, setShowMessage] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();

  const [isVerified, setIsVerified] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // New state for submitting
  const [resetCaptcha, setResetCaptcha] = useState(false);
  useEffect(() => {
    if (resetCaptcha) {
      setResetCaptcha(false); // Clear the reset state after it has been used
    }
  }, [resetCaptcha]);

  const showMessageWithType = (type, msg) => {
    setMessageType(type);
    setMessage(msg);
    setShowMessage(true);
  };

  useEffect(() => {
    if (showMessage) {
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [showMessage]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return; // Prevent multiple submissions
    setIsSubmitting(true); // Set submitting state

    if (!username || !password) {
      showMessageWithType("error", "Please fill Username and password.");
      setIsSubmitting(false);
      return;
    }
    if (!isVerified) {
      showMessageWithType("error", "Please complete the captcha");
      setIsSubmitting(false);
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/adminlogin`,
        { username, password }
      );
      if (response.status === 200 && response.data.token) {
        localStorage.setItem("token", response.data.token);
        setToken(response.data.token);
        navigate("/admingallery");
      } else {
        showMessageWithType(response.data.status, response.data.message);
        localStorage.removeItem("token");
      }
    } catch (err) {
      const errorMessage =
        err.response?.data?.message || "Incorrect username or password";
      showMessageWithType("error", errorMessage);
    } finally {
      setResetCaptcha(true); // Reset captcha on error
      setIsSubmitting(false); // Reset submitting state
    }
  };

  return (
    <div className="container">
      <div className="admin-wrap">
        <div className="box-head">
          <span>Admin Login</span>
        </div>
        <form name="adminlogin" className="login-form" onSubmit={handleSubmit}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <img src="/Images/adminLogin.png" alt=""></img>
          </div>
          <div className="form-group">
            <label htmlFor="username">User Name</label>
            <input
              id="username"
              type="text"
              name="username"
              autoComplete="name"
              required
              placeholder="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              id="password"
              type="password"
              name="password"
              autoComplete="current-password"
              placeholder="Password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="form-group">
            <MathCaptcha onVerification={setIsVerified} reset={resetCaptcha} />
          </div>
          <div style={{ textAlign: "center" }}>
            <button
              type="submit"
              className="readMoreButton"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Submitting..." : "Submit"}
            </button>
          </div>
          {showMessage && (
            <p className={`${messageType} ${!showMessage && "hidden"}`}>
              {message}
            </p>
          )}
        </form>
      </div>
    </div>
  );
};

export default AdminLogin;
