import React, { useEffect, useState, useRef } from "react";
import axios from "axios";

const LatestCirculars = () => {
  const [pdfArray, setPdfArray] = useState([]);
  const listRef = useRef(null);
  const scrollIntervalRef = useRef(null);

  useEffect(() => {
    const listElement = listRef.current;

    const startAutoScroll = () => {
      if (!listElement) return; // Ensure the element is available before starting

      scrollIntervalRef.current = setInterval(() => {
        listElement.scrollTop += 1;

        // Reset to the top if the end is reached
        if (
          listElement.scrollTop + listElement.clientHeight >=
          listElement.scrollHeight
        ) {
          listElement.scrollTop = 0;
        }
      }, 50); // Adjust speed here (50ms delay between scrolls)
    };

    // Start scrolling once the component mounts and PDF data is loaded
    startAutoScroll();

    // Clear the interval on component unmount
    return () => clearInterval(scrollIntervalRef.current);
  }, [pdfArray]); // Depend on `pdfArray` so it starts scrolling once data is available

  const handleMouseEnter = () => {
    // Pause the scroll when hovering over the list
    clearInterval(scrollIntervalRef.current);
  };

  const handleMouseLeave = () => {
    // Resume the scroll when not hovering
    const listElement = listRef.current;
    scrollIntervalRef.current = setInterval(() => {
      if (listElement) {
        listElement.scrollTop += 1;

        if (
          listElement.scrollTop + listElement.clientHeight >=
          listElement.scrollHeight
        ) {
          listElement.scrollTop = 0;
        }
      }
    }, 50); // Scroll speed: moves 1 pixel every 50ms
  };

  // Call the API to get the list of circulars
  useEffect(() => {
    const fetchedPdfFiles = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/getCirculars`
        );
        setPdfArray(response.data.pdfArray);
      } catch (error) {
        console.error("Error fetching PDF files:", error);
      }
    };

    fetchedPdfFiles();
  }, []);

  if (!pdfArray || pdfArray.length === 0) {
    return <p>No circulars available</p>;
  }

  return (
    <div
      className="pdfListContainer"
      ref={listRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{ overflowY: "auto", maxHeight: "400px" }} // Ensure the container can scroll
    >
      <ul className="pdfList">
        {pdfArray.slice(0, 10).map((pdf) => (
          <li key={pdf.id} className="pdfListItem">
            <div className="notice-row">
              <a href={pdf.url} target="_blank" rel="noopener noreferrer">
                {pdf.realname}
              </a>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LatestCirculars;
