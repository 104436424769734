import React, { useState, useEffect } from "react";
// import "./Carousal.css";

const images = [
  "/Images/SliderImage/1.jpg",
  "/Images/SliderImage/2.jpg",
  "/Images/SliderImage/3.jpg",
  // Add more image paths here
];

function Carousel() {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change image every 3 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="mlc-carousel">
      {images.map((image, index) => (
        <div
          className={`mlc-carousel-slide ${
            index === currentIndex ? "active" : ""
          }`}
          key={index}
          style={{ backgroundImage: `url(${image})` }}
        ></div>
      ))}
    </div>
  );
}

export default Carousel;
