import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HeadComponent from "../../Components/UI/HeadComponent";
import axios from "axios";
import MathCaptcha from "../../Components/UI/MathCaptcha";

const ForgotPassword = () => {
  const [showMessage, setShowMessage] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  const navigate = useNavigate();
  const [isVerified, setIsVerified] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // New state for submitting
  const [resetCaptcha, setResetCaptcha] = useState(false);
  useEffect(() => {
    if (resetCaptcha) {
      setResetCaptcha(false); // Clear the reset state after it has been used
    }
  }, [resetCaptcha]);

  const showMessageWithType = (type, msg) => {
    setMessageType(type);
    setMessage(msg);
    setShowMessage(true);
    setIsSuccess(type === "success");
  };

  // Email validation function
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  useEffect(() => {
    if (showMessage) {
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 5000); // Display message for 5 seconds
      return () => clearTimeout(timer);
    }
  }, [showMessage]);

  // Redirect after successful password reset request
  useEffect(() => {
    if (isSuccess) {
      const redirectTimer = setTimeout(() => {
        navigate("/");
      }, 15000);
      return () => clearTimeout(redirectTimer);
    }
  }, [isSuccess, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return; // Prevent multiple submissions
    setIsSubmitting(true); // Set submitting state

    if (!validateEmail(email)) {
      showMessageWithType("error", "Please enter a valid email.");
      setIsSubmitting(false);
      return;
    }
    //needed to verify the captcha
    if (!isVerified) {
      showMessageWithType("error", "Please complete the captcha");
      setIsSubmitting(false);
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/requestforpasswordreset`,
        { email }
      );
      showMessageWithType(response.data.status, response.data.message);
    } catch (error) {
      showMessageWithType("error", "An error occurred. Please try again.");
    } finally {
      setEmail("");
      setResetCaptcha(true); // Reset captcha on error
      setIsSubmitting(false); // Reset submitting state
    }
  };

  return (
    <>
      <HeadComponent />
      <div className="container">
        <div className="admin-wrap">
          <div className="box-head">
            <span>Forgot Password</span>
          </div>
          <div className="upload-container">
            <form className="login-form" onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="email">Email</label>
                <input
                  id="email"
                  type="email"
                  name="email"
                  autoComplete="email"
                  required
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="form-group">
                <MathCaptcha
                  onVerification={setIsVerified}
                  reset={resetCaptcha}
                />
              </div>
              <div style={{ textAlign: "center" }}>
                <button
                  type="submit"
                  className="readMoreButton"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </button>
              </div>
              <div>
                {showMessage && (
                  <p className={`${messageType} ${!showMessage && "hidden"}`}>
                    {message}
                  </p>
                )}
              </div>
            </form>

            {isSuccess && (
              <div
                style={{
                  textAlign: "center",
                  marginTop: "20px",
                  padding: "20px",
                }}
              >
                <p>
                  A password reset link has been sent to your email address! You
                  can reset your password using the link sent to your email.
                  Please check your spam/junk folder if you don’t see the email
                  in your inbox. You will be redirected to the home page in 15
                  seconds.
                </p>
                <p>
                  If not redirected, click <a href="/">here</a> to go to the
                  home page.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
