import React, { useState } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  const menuItems = [
    { name: "Home", link: "/" },
    {
      name: "About Us",
      submenu: [
        { name: "About Us", link: "/about-us" },
        { name: "Chairman's Message", link: "/chairmans-message" },
        { name: "Vision and Mission", link: "/vision-and-mission" },
        { name: "Aim and Objective", link: "/aim-and-objective" },
        { name: "Dress Code", link: "/dress-code" },
        { name: "Discipline", link: "/discipline" },
        { name: "General Rules", link: "/general-rules" },
      ],
    },
    {
      name: "Courses",
      submenu: [
        {
          name: "Courses & Programmes",
          link: "/courses-and-programmes",
        },
      ],
    },
    {
      name: "Academics",
      submenu: [
        { name: "Admission Procedure", link: "/admission-procedure" },
        { name: "Notices & Circulars", link: "/allcirculars" },
      ],
    },
    { name: "Gallery", link: "/imagegallery" },
    { name: "Contact Us", link: "/contactus" },
    {
      name: "Login",
      submenu: [
        { name: "Admin login", link: "/adminlogin" },
        { name: "Forgot Password", link: "/forgotpassword" },
        // { name: "Reset Password", link: "/resetpassword" },
      ],
    },
    { name: "Enquiry", link: "/enquiry" },
  ];

  const [activeMenu, setActiveMenu] = useState(null);

  const selectedNavbarMenuItem = (index) => {
    setActiveMenu(index);
  };

  const handleMouseLeave = () => {
    setActiveMenu(null);
  };

  return (
    <ul className="navbar-items" id="navbar-menu">
      {menuItems.map((item, index) => (
        <li
          key={item.name} // Use a unique key for each <li>
          className="navbar-items-li"
          onMouseEnter={() => item.submenu && selectedNavbarMenuItem(index)}
          onMouseLeave={() => item.submenu && handleMouseLeave()}
        >
          {/* Link inside the li */}
          <Link to={item.link || "#"} className="navbar-items-link">
            {item.name}
          </Link>

          {/* Submenu items */}
          {item.submenu && activeMenu === index && (
            <ul className="submenuContainer">
              {item.submenu.map((subItem) => (
                <li key={subItem.name} className="submenu-li">
                  <Link to={subItem.link} className="submenu-link">
                    {subItem.name}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  );
};

export default Navbar;
