import React from "react";
// import "./Brand.css";

function Brand() {
  return (
    <div className="brand-content-container">
      <a href="/">
        <img
          className="brand-responsive-image"
          src="/Images/logo150x150.png"
          alt="logo"
        />
      </a>
      <div>
        <h5 className="brand-content-container-h5">
          Maheshwaram College of Education and Law Management
        </h5>
        <h5 className="brand-content-container-h5">
          माहेश्वरम कॉलेज ऑफ एजुकेशन एंड लॉ मैनेजमेंट
        </h5>

        <p className="brand-content-container-p">
          Affiliated to Chaudhary Charan Singh University, Meerut & Approved by
          BCI, New Delhi
        </p>
      </div>
    </div>
  );
}

export default Brand;
