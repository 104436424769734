import React from "react";
import "../App.css";
import { useNavigate } from "react-router-dom";
import QuickLinks from "./UI/QuickLinks";
import SocialNavbar from "./UI/SocialNavbar";

function Footer() {
  const iconStyle = {
    marginRight: "0.5rem",
    color: "white",
    textDecoration: "none",
  };
  const navigate = useNavigate();

  return (
    <>
      <div className="footer">
        <div className="footer-upper">
          <div className="footer-column">
            <h6 className="divider-full-width">About Us</h6>
            <div style={{ padding: "0 1rem 1rem 1rem" }}>
              <p className="tabbed-paragraph">
                Maheshwaram College of Education and Law Management, located in
                Bulandshahr, is renowned as one of the premier law colleges in
                Uttar Pradesh. The institution is dedicated ...
              </p>
            </div>
            <div style={{ textAlign: "right" }}>
              <button
                className="readMoreButton"
                onClick={() => {
                  navigate("/about-us");
                  window.scrollTo({ top: 0, behavior: "smooth" });
                }}
              >
                Read More ...
              </button>
            </div>
          </div>
          <div className="footer-column">
            <h6 className="divider-full-width">Quick Links</h6>
            {/* <CollegeLocation /> */}
            <QuickLinks />
            <SocialNavbar
              facebookURL={"https://www.facebook.com/"}
              twitterUrl={"https://x.com/"}
              instagramUrl={"https://www.instagram.com"}
              youtubeUrl={"https://youtube.com/"}
              telegramUrl={"https://telegram.org/"}
              whatsappUrl={"https://web.whatsapp.com/"}
            />
          </div>
          <div className="footer-column">
            <h6 className="divider-full-width">Contact Us</h6>
            <p className="no-margin">
              <span>
                Maheshwaram College of Education And Law Management, Bhensora,
                Near Siyana, Bulandshahr, Uttar Pradesh Pin : 245412
                <br />
                {"\uD83D\uDCF1"} 9997908588 , 8938855524
                <br />
                {"\uD83D\uDCF1"} 9760082425 , 9627250700
              </span>
              <br />
              <a
                style={iconStyle}
                href="mailto:principalmclb.gmail.com"
                target="_blank"
                rel="noreferrer"
              >
                ✉️ principalmclb@gmail.com
              </a>
            </p>
          </div>
        </div>
        <div className="footer-lower">
          <p className="no-margin" style={{ textAlign: "center" }}>
            Copyright © 2024 Maheshwaram College of Education & Law Management.
            All rights reserved.
          </p>
          <p className="no-margin" style={{ textAlign: "center" }}>
            <span>
              Designed &amp; Developed By:{" "}
              <a
                href="mailto:sharmakk@live.com"
                target="_blank"
                rel="noreferrer"
              >
                Krishna Infotech Pvt. Ltd.
              </a>
            </span>{" "}
          </p>
        </div>
      </div>
    </>
  );
}

export default Footer;
