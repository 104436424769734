import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

const AdminNavbar = () => {
  const navigate = useNavigate();
  const menuItems = [
    { name: "Upload Photo", link: "/uploadphoto" },
    { name: "Upload Circular", link: "/uploadcircular" },
    // { name: "Manage Photos", link: "/admingallery" },
    // { name: "Manage Circular", link: "/admincirculars" },
    { name: "Change Password", link: "/changepassword" },
    { name: "Reports", link: "/reportenquiry" },
    { name: "Logout", link: "/logout" },
  ];

  // State to track which menu item is active for submenu visibility
  const [activeMenu, setActiveMenu] = useState(null);

  // Handle mouse enter to show submenu
  const selectedNavbarMenuItem = (name) => {
    setActiveMenu(name);
  };

  // Handle mouse leave to hide submenu
  const handleMouseLeave = () => {
    setActiveMenu(null);
  };
  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
    window.location.reload();
  };
  return (
    <>
      {/* <div className="adminnavbar-container"></div> */}
      <ul className="navbar-items" id="Adminnavbar-menu">
        {menuItems.map((item, index) => (
          <li
            key={index}
            className="navbar-items-li"
            onMouseEnter={() => item.submenu && selectedNavbarMenuItem(index)}
            onMouseLeave={() =>
              item.submenu &&
              handleMouseLeave() &&
              selectedNavbarMenuItem(index)
            }
          >
            <Link
              to={item.link}
              className="navbar-items-link"
              style={
                item.name === "Logout"
                  ? {
                      color: "red",
                      backgroundColor: "gold",
                      padding: ".2rem 1.5rem .3rem 1.2rem",
                      borderRadius: "1rem",
                    }
                  : {}
              }
              onClick={item.name === "Logout" ? handleLogout : null}
            >
              {item.name}
            </Link>

            {/* Submenu items */}
            {item.submenu && activeMenu === index && (
              <ul className="submenuContainer">
                {item.submenu.map((subItem, subIndex) => (
                  <li key={subIndex} className="submenu-li">
                    <Link to={subItem.link} className="submenu-link">
                      {subItem.name}
                    </Link>
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>
    </>
  );
};

export default AdminNavbar;
