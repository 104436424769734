import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// Tags (menu items) with submenus
const Tags = [
  { name: "Upload Photo", link: "/uploadphoto" },
  { name: "Upload Circular", link: "/uploadcircular" },
  // { name: "Manage Photos", link: "/admingallery" },
  // { name: "Manage Circular", link: "/admincirculars" },
  { name: "Change Password", link: "/changepassword" },
  { name: "Reports", link: "/reportenquiry" },
  { name: "Logout", link: "/logout" },
];

const AdminSidebar = ({ toggleAdminsidebar, setToggleAdminsidebar }) => {
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const navigate = useNavigate();
  // Toggle submenu visibility
  const handleSubMenuToggle = (index) => {
    setActiveSubMenu(activeSubMenu === index ? null : index);
  };

  // Handle menu item click
  const handleMenuItemClick = (index) => {
    setActiveSubMenu(activeSubMenu === index ? null : index);
    setToggleAdminsidebar(false); // Close the sidebar
  };
  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
    window.location.reload();
  };
  return (
    toggleAdminsidebar && (
      <div className="sidebar-container">
        <ul className="sidebar-ul">
          {Tags.map((item, index) => (
            <li key={index} style={{ marginBottom: "0.5rem" }}>
              {" "}
              <Link
                to={item.link}
                className="adminsidebar-link"
                onClick={() => {
                  if (item.name === "Logout") {
                    handleLogout();
                  } else {
                    if (item.submenu) {
                      handleSubMenuToggle(index);
                    } else {
                      handleMenuItemClick(index);
                    }
                  }
                }}
              >
                {item.name}
              </Link>
              {/* Render submenu if available */}
              {item.submenu && activeSubMenu === index && (
                <ul className="sidebar-submenu-ul">
                  {item.submenu.map((subItem, subIndex) => (
                    <li key={subIndex} style={{ marginBottom: "0.25rem" }}>
                      <Link
                        to={subItem.link}
                        className="sidebar-submenu-link"
                        onClick={() => handleMenuItemClick(index)}
                      >
                        {subItem.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
    )
  );
};

export default AdminSidebar;
