import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import MathCaptcha from "../../Components/UI/MathCaptcha";

const Registeruser = () => {
  const [fullname, setFullname] = useState("");
  const [email, setEmail] = useState(""); // This is the email
  const [mobileno, setMobileno] = useState("");
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmpassword] = useState("");

  const [isVerified, setIsVerified] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // New state for submitting
  const [resetCaptcha, setResetCaptcha] = useState(false);
 
  useEffect(() => {
    if (resetCaptcha) {
      setResetCaptcha(false); // Clear the reset state after it has been used
    }
  }, [resetCaptcha]);

  const [showMessage, setShowMessage] = useState(false);
  const [messageType, setMessageType] = useState("");
  const [message, setMessage] = useState("");
  const showMessageWithType = (type, msg) => {
    setMessageType(type);
    setMessage(msg);
    setShowMessage(true);
  };

  useEffect(() => {
    if (showMessage) {
      const timer = setTimeout(() => {
        setShowMessage(false);
      }, 10000); // Display message for 10 seconds
      return () => clearTimeout(timer);
    }
  }, [showMessage]);

  // Allows letters, spaces, hyphens, and apostrophes min length 3
  const validateFullName = (name) => {
    const fullNameRegex = /^[a-zA-Z\s'-]{3,100}$/;
    return fullNameRegex.test(name);
  };

  // Email validation function
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Mobile number validation function
  const validateMobileNumber = (mobileno) => {
    const mobileRegex = /^[6-9]\d{9}$/;
    return mobileRegex.test(mobileno);
  };
  const registeruser = useRef(null);
  const resetForm = () => {
    registeruser.current.reset();
    setFullname("");
    setEmail("");
    setMobileno("");
    setPassword("");
    setConfirmpassword("");
    setIsSubmitting(false);
    setIsVerified(false);
    setResetCaptcha(true);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isSubmitting) return; // Prevent multiple submissions
    setIsSubmitting(true); // Set submitting state

    // Client-side validations
    if (!fullname || !email || !mobileno || !password || !confirmpassword) {
      showMessageWithType("error", "Please fill all fields.");
      setIsSubmitting(false);
      return;
    }

    if (!validateFullName(fullname)) {
      showMessageWithType(
        "error",
        "Full name can only contain letters, spaces, hyphens, and apostrophes."
      );
      setIsSubmitting(false);
      return;
    }

    if (!validateEmail(email)) {
      showMessageWithType("error", "Please enter a valid email.");
      setIsSubmitting(false);
      return;
    }

    if (!validateMobileNumber(mobileno)) {
      showMessageWithType(
        "error",
        "Please enter a valid 10-digit mobile number starting with 6-9."
      );
      setIsSubmitting(false);
      return;
    }

    if (password.length < 6) {
      showMessageWithType(
        "error",
        "Password must be at least 6 characters long."
      );
      setIsSubmitting(false);
      return;
    }

    if (password !== confirmpassword) {
      showMessageWithType("error", "Passwords do not match.");
      setIsSubmitting(false);
      return;
    }
    //verify captcha
    if (!isVerified) {
      showMessageWithType("error", "Please complete the captcha");
      setIsSubmitting(false);
      return;
    }
    // call API  to register user
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/registerUser`,
        { fullname, email, mobileno, password }
      );
      if (response.status === 200 && response.data.status === "success") {
        showMessageWithType(
          response.data.status,
          response.data.message || "Registered successfully!"
        );
        resetForm();
      } else {
        showMessageWithType(response.data.status, response.data.message);
      }
    } catch (err) {
      const errorMessage =
        err.response?.data?.message || "Something went wrong, try again later.";
      showMessageWithType("error", errorMessage);
    } finally {
      setResetCaptcha(true); // Reset captcha on error
      setIsSubmitting(false); // Reset submitting state
    }
  };

  return (
    <div className="container">
      <div className="admin-wrap">
        <div className="box-head">
          <span>Student Registration</span>
        </div>
        <form
          name="registeruser"
          className="login-form"
          onSubmit={handleSubmit}
          ref={registeruser}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <img
              style={{ width: "70px", height: "auto", margin: "0,auto" }}
              src="/Images/registration.png"
              alt=""
            />
          </div>
          <div className="form-group">
            <label htmlFor="fullname">Name</label>
            <input
              id="fullname"
              type="text"
              name="fullname"
              autoComplete="name"
              required
              placeholder="Full Name"
              value={fullname}
              onChange={(e) => setFullname(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="username">Email</label>
            <input
              id="username"
              type="email"
              name="username"
              autoComplete="username"
              required
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="mobileno">Mobile No</label>
            <input
              id="mobileno"
              type="text"
              name="mobileno"
              autoComplete="tel"
              placeholder="mobile no"
              required
              value={mobileno}
              onChange={(e) => setMobileno(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              id="password"
              type="password"
              name="password"
              autoComplete="new-password"
              placeholder="Password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="confirmpassword">Confirm Password</label>
            <input
              id="confirmpassword"
              type="password"
              name="confirmpassword"
              autoComplete="off"
              placeholder="Confirm Password"
              required
              value={confirmpassword}
              onChange={(e) => setConfirmpassword(e.target.value)}
            />
          </div>
          <div className="form-group">
            <MathCaptcha onVerification={setIsVerified} reset={resetCaptcha} />
          </div>
          <div style={{ textAlign: "center" }}>
            <button
              type="submit"
              className="readMoreButton"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Submitting..." : "Submit"}
            </button>
          </div>
          {showMessage && (
            <p className={`${messageType} ${!showMessage && "hidden"}`}>
              {message}
            </p>
          )}
        </form>
      </div>
    </div>
  );
};

export default Registeruser;
