import React, { useEffect, useState } from "react";
import axios from "axios";

const AllCirculars = () => {
  const [pdfArray, setPdfArray] = useState([]);

  //call the Api to get list of circulars
  useEffect(() => {
    const fetchedPdfFiles = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/getCirculars`
        );
        setPdfArray(response.data.pdfArray);
      } catch (error) {
        console.error("Error fetching PDF files:", error);
      }
    };

    fetchedPdfFiles();
  }, []);

  return (
    <div>
      {/* <h6 className="divider-full-width">Uploaded Circulars</h6> */}
      <div className="page-head">
        <span>Notices & Circulars</span>
      </div>
      <table className="pdf-table">
        <thead>
          <tr>
            <th>Title</th>
            <th>Posted On</th>
            <th>Download</th>
          </tr>
        </thead>
        <tbody>
          {pdfArray.map((pdf) => (
            <tr key={pdf.id}>
              <td>
                <img
                  src="/Images/pdf.png"
                  alt=""
                  style={{ width: "24px", height: "24px", marginRight: "8px" }}
                />
                {pdf.realname}
              </td>
              <td style={{ textAlign: "center" }}>{pdf.uploaded_at}</td>
              <td style={{ textAlign: "center" }}>
                <a
                  href={pdf.url}
                  download={pdf.name}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Download
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="divider-full-width"></div>
    </div>
  );
};

export default AllCirculars;
