import React from "react";
import { useNavigate } from "react-router-dom";

const Logout = ({ style }) => {
  const navigate = useNavigate();

  // Function to handle logout
  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
    window.location.reload();
  };
  return (
    <div>
      <button  onClick={handleLogout} style={style} >
        Logout
      </button>
    </div>
  );
};

export default Logout;
