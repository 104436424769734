import React from "react";

function Dresscode() {
  return (
    <div>
      <div className="page-head">
        <span>Dress Code</span>
      </div>

      <p className="tabbed-paragraph">
        The Institute has prescribed the following dress code which is to be
        observed on all working days and also on every important visit or
        lecture by visiting dignitaries/ guest speakers:-
      </p>
      <table className="pdf-table">
        <thead>
          <tr>
            <th className="border border-slate-600">Season</th>
            <th className="border border-slate-600">Boys</th>
            <th className="border border-slate-600">Girls</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="border border-slate-700">Summer</td>
            <td className="border border-slate-600">
              Black trousers, White full sleeved shirt tucked-in, Black Tie,
              Black leather shoes
            </td>
            <td className="border border-slate-600">
              Black trousers, White full sleeved shirt tucked-in, Black Tie,
              Black leather shoes and Black socks. Or White salwar-kameez, Black
              dupatta, Black leather shoes and White socks. Or Black printed
              sharee with White full sleeved blouse, Black leather shoes and
              black socks.
            </td>
          </tr>
          <tr>
            <td className="border border-slate-600">Winter</td>
            <td className="border border-slate-600">
              Black blazer, Black trousers, White shirt, White full sleeved
              shirt tucked-in, Black Tie, Black leather shoes and Black socks.
            </td>
            <td className="border border-slate-600">
              Black blazer, Black trousers, White full sleeved shirt tucked-in,
              Black Tie, Black leather shoes and Black socks. Or Black blazer,
              White salwar-kameez, Black dupatta, Black leather shoes and White
              socks. Or Black blazer, Black Printed Sharee with white blouse,
              black leather shoes and black socks.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default Dresscode;
