import React, { useState, useEffect } from "react";
import axios from "axios";

const ImageGallery = () => {
  const [images, setimages] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const openGallery = (index) => {
    setCurrentIndex(index);
    setIsOpen(true);
  };

  const closeGallery = () => {
    setIsOpen(false);
  };

  const goToPrevious = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };
  //call the Api to get list of photos
  useEffect(() => {
    const fetchedPhotos = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/getPhotos`
        );
        setimages(response.data.images);
      } catch (error) {
        console.error("Error fetching PDF files:", error);
      }
    };

    fetchedPhotos();
  }, []);

  return (
    <div className="image-grid-container">
      <div className="image-grid">
        {images.map((image, index) => (
          <img
            key={index}
            src={image.imageurl}
            alt={image.imagename}
            onClick={() => openGallery(index)}
            className="thumbnail"
          />
        ))}
      </div>

      {isOpen && (
        <div className="lightbox-overlay" onClick={closeGallery}>
          <div
            className="lightbox-content"
            onClick={(e) => e.stopPropagation()}
          >
            <button className="lightbox-nav prev" onClick={goToPrevious}>
              &#10094; {/* Left arrow symbol */}
            </button>
            <img
              src={images[currentIndex].imageurl}
              alt={images[currentIndex].imagename}
              className="lightbox-image"
            />
            <button className="lightbox-nav next" onClick={goToNext}>
              &#10095; {/* Right arrow symbol */}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageGallery;
